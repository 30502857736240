import React from 'react'
import { Link } from 'gatsby'

const HeaderGeneric = (props) => (
    <header id="header">
        <Link to="/">
            <h1>build for Cause</h1>
        </Link>
        <p>empowering nonprofits - one cause at a time</p>
    </header>
)

export default HeaderGeneric
