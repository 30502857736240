import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/pic04.jpg'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="About - build for Cause" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>
            <h2>About</h2>
            <p>We're a community of IT professionals striving for giving back to the community by donating skills and hours. We believe that the IT needs of the nonprofit organizations are growing much than ever before - and trying to help as much as we can by making ourselves available for them. We do not charge for our services instead we're proud and satiesfied to donate the skills and hours.</p>
          </section>
          <section id="first" className="main special">
            <header className="major">
              <h2>Our skills</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Website & Portals</h3>
                <p>
                Website to showcase the good deeds and Content management 
                with required features - Portal, User System, eCommerce etc
                </p>
              </li>
              <li>
                <span className="icon major style3 fas fa-gears"></span>
                <h3>Campaign Automatoin</h3>
                <p>
                Setting up a smooth campaign automation (Newsletter, Social Media, 
                Goal based etc.) system to easy to use tools.
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-money"></span>
                <h3>Fundraising</h3>
                <p>
                A painless fundraising system with affordable tools with the 
                features to integrate to all you existing mode of campaigns.
                </p>
              </li>

              <li>
                <span className="icon major style5 fas fa-user-plus"></span>
                <h3>CRM</h3>
                <p>
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-building-o"></span>
                <h3>HR</h3>
                <p>
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-credit-card-alt"></span>
                <h3>Payroll</h3>
                <p>
                </p>
              </li>

              <li>
                <span className="icon major style5 fas fa-facebook-official"></span>
                <h3>Social Media</h3>
                <p>
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-envelope"></span>
                <h3>Email marketing</h3>
                <p>
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-align-justify"></span>
                <h3>Forms & Surveys</h3>
                <p>
                </p>
              </li>

              <li>
                <span className="icon major style5 fas fa-comments"></span>
                <h3>Live chat</h3>
                <p>
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-calendar-plus-o"></span>
                <h3>Event management</h3>
                <p>
                </p>
              </li>
              <li>
                <span className="icon major style5 fas fa-shopping-cart"></span>
                <h3>eCommerce</h3>
                <p>
                </p>
              </li>

            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://wa.me/14049414116" className="button special">
                  <span className="icon fas fa-whatsapp"></span> WhatsApp!
                  </a>
                </li>
                <li>                
                  <a href="https://m.me/buildforcause" className="button">
                  <span className="icon style5 fas fa-facebook"></span> Messenger!
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
